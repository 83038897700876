.ant-table-thead > tr > th {
  border-bottom: none;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #ebebeb;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-hover {
  background: white;
}

.ant-btn-default {
  border: 1px solid #e9eaeb;
}

.ant-input {
  border-radius: 6px;
}

.ant-input-number {
  border-radius: 6px;
}

.ant-input-number-handler-wrap {
  border-radius: 0 6px 6px 0;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}

.ant-input-affix-wrapper {
  border-radius: 6px;
}

.ant-picker {
  border-radius: 6px;
}
.ant-modal-content {
  border-radius: 16px;
}

.ant-modal-header {
  border-radius: 16px;
  border-bottom: none;
}

.ant-modal-title {
  font-weight: 600;
  line-height: 32px;
}

.ant-modal-footer {
  border-top: none;
  padding: 10px 24px 24px 10px;

}

.ant-form {
  label {
    font-weight: 500;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}

.ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}

.ant-drawer-header-title {
  display: flex;
  flex-direction: row-reverse;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  .ant-drawer-title {
    flex: 1 1;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
  }
}
