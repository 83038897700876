// @import 'react-big-calendar/lib/sass/styles';
// @import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

.rbc-day-slot .rbc-event,
.rbc-event {
  background: #e8ffed;

  min-height: 20px;
  color: #2e2e2e;
  font-size: 12px;
  border: 1px solid #3ebd73;
}

.rbc-current-time-indicator {
  background-color: #f43f24;
}

.rbc-current-time-indicator::before {
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  content: ' ';
  background-color: #f43f24;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.rbc-event.rbc-selected {
  background-color: #daf1df;
}
.rbc-toolbar {
  flex-direction: row;
  flex-wrap: nowrap;
  height: 36px;
  .rbc-toolbar-label {
    font-size: 22px;
    font-weight: 500;
    color: #0d0d0d;
    margin-left: -0.5rem;
    letter-spacing: -1px;
  }

  button {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    font-weight: 500;
    font-size: 14px;
    color: #2b2b2b;
    margin-right: 2px;
    padding: 6px 14px;
  }
}

.rbc-toolbar button:focus {
  background-color: #ffffff;
  border-color: #ebebeb;
}

.rbc-month-view {
  border: none;
  border-top: none;
  background-color: white;
  padding: 30px 20px;
  border-radius: 16px;
}

.rbc-header {
  border-left: none;
  color: #5c5c5c;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
  padding-bottom: 14px;
  border-bottom: 1px solid #ebebeb;
}

.rbc-header + .rbc-header {
  border-left: none;
  background-color: #fff;
}

.rbc-month-row {
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ebebeb;
}

.rbc-month-row + .rbc-month-row {
  border-top: none;
}

.rbc-today {
  background-color: #f9f9fa;
}

.rbc-date-cell {
  padding-top: 8px;
  color: #0d0d0d;
  text-align: center;
  font-size: 12px;
}

.rbc-off-range-bg {
  background: none;
}
.rbc-off-range {
  color: #777777;
}

.rbc-date-cell.rbc-now {
  color: #3ebd73;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-view {
  border: none;
  background-color: white;
  padding: 30px 0px;
  border-radius: 16px;
}

.rbc-time-slot {
  .rbc-label {
    padding: 0px 20px;
  }
}

.rbc-day-slot .rbc-time-slot {
  border-bottom: 1px solid #f8f8f8;
}

.rbc-time-content > .rbc-time-gutter {
  .rbc-timeslot-group {
    border-bottom: none;
  }
}

.rbc-timeslot-group {
  border-bottom: 1px solid #ebebeb;
  font-size: 12px;
  color: #5c5c5c;
  min-height: 90px;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #ebebeb;
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
}

.rbc-time-header-content {
  border-left: none;
}

.rbc-time-content {
  border-top: none;
}

.rbc-agenda-table {
  tr {
    background-color: white !important;
    border: 1px solid #ebebeb !important;
  }
}

.rbc-show-more {
  font-weight: 400;
  color: #7a7b7c;
  padding: 3px 6px;
  border-radius: 6px;
  &:hover {
    background-color: #f4f5f7;
    color: #7a7b7c;
  }
}

.rbc-overlay {
  border: 1px solid #d9dbdc;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.rbc-overlay-header {
  text-align: center;
  border-bottom: none;
}
