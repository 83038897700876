// @import '~antd/dist/antd.min.css';
@import './antd-custom/result.css';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';
@import './antd-custom/antd-styles.scss';
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');

* {
  // font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  font-family: 'Pretendard';
  word-break: keep-all;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    background-color: #d9dbdc;

    &:hover {
      background-color: #c2c3c4;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.content-wrapper {
  & + & {
    margin-top: 16px;
  }
}

body {
  @media (max-width: 820px) {
    overflow-x: hidden;
  }
}

a {
  text-decoration: none;
}

.green-btn {
  background-color: white;
  border-color: #4CAF50;
  color: #4CAF50;
}

.red-btn {
  background-color: white;
  border-color: #ff4d4f;
  color: #ff4d4f;
}